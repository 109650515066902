<template>
  <div
    class="credit-page"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\';'"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-index.png')" />
      <div class="credit-info">
		<div style="display: flex;">
<!-- 			<div class="credit">
			   <span>已繳納品牌使用費</span>
			   <span class="num price">{{FiltergoodsMoney}}</span>
			</div> -->
			<div class="credit">
			   <span>待繳納品牌使用費</span>
			   <span class="num price">{{FilterinMoney}}</span>
			</div>
			
		</div>  
      </div>
    </div>

    <ul class="credit-menu">
      <li>
        <a href="#/depositMoneyLog">
          <span class="title">
            <i class="iconfont icon-jilu" style="color: #3cadff" />
              繳納記錄
          </span>
        </a>
      </li>
	  <li>
	    <a href="#/depositMoneyPayLog">
	      <span class="title">
	        <i class="iconfont icon-jilu" style="color: #3cadff" />
	            补繳記錄
	      </span>
	    </a>
	  </li>
    </ul>
  </div>
</template>

<script>
import { closeToast, showLoadingToast, showToast } from "vant";
import { getCookie } from "../util";

export default {
  computed:{
	   FiltergoodsMoney() {
	        return this.agentInfo.depositMoney/100
	   },
	   FilterinMoney(){
		   return this.agentInfo.needDepositMoney/100
	   },
	   FilteroutMoney(){
		   return this.agentInfo.outMoney/100
	   }
		 
  },
  data() {
    return {
      agentInfo: {},
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;

    showLoadingToast({
      message: this.SYSLANG.loading,
      duration: 0,
      forbidClick: true,
    });

    this.axios
      .get(this.actions.memberDeposit)
      .then((res) => {
        closeToast();
        let { code, message, data } = res.data;
        console.log(code, message);
        if (code == 200) {
          this.agentInfo = data;
        } else {
          showToast({
            message:
              code == "NOTLOGIN" ? this.SYSLANG[message] : this.PAGELANG[message],
            forbidClick: true,
            onClose: () => {
              if (code == "NOTLOGIN") {
                this.$router.replace({
                  path: "/login",
                });
              }
            },
          });
        }
      })
      .catch((error) =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      );
  },
};
</script>


<style src="../assets/css/credit.css" scoped></style>